import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Provider,
  forwardRef,
  inject,
  input,
  signal, AfterViewInit,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';

const FILEUPLOAD_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormFileUploadComponent),
  multi: true,
};

@Component({
  selector: 'app-form-file-upload',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
  templateUrl: './form-file-upload.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [FILEUPLOAD_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFileUploadComponent implements ControlValueAccessor, AfterViewInit {
  control = signal<FormControl>(new FormControl());

  inputId = input.required<string>();
  labelName = input.required<string>();

  value = signal<File | null>(null);
  disabled = signal<boolean>(false);

  private onTouched: Function = () => {};
  private onChanged: Function = () => {};

  injector = inject(Injector);
  private _cdr = inject(ChangeDetectorRef);

  file: File | null = null;

  ngAfterViewInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control.set(ngControl.control as FormControl);
    }
  }

  inputOnChanged(event: Event) {
    this.file = null;
    this.setValue((event.target as any).value, true);
  }

  protected setValue(obj: File, emitEvent: boolean) {
    //this.value.set(obj);
    this.file = obj;
    console.log(this.file);
    
    if (emitEvent && this.onChanged) {
      this.onChanged(obj);
      this.onTouched();
    }
  }

  writeValue(obj: any): void {
    this.setValue(obj, false);
    this._cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  formatBytes(bytes: number): string {
    const units = ['kB', 'MB', 'GB'];
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
        return bytes + 'B';
    }

    let u = -1
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * 100) / 100 >= thresh)

    return bytes.toFixed(2) + ' ' + units[u];    
  }
}