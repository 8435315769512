﻿<div class="h-full w-full flex items-center justify-center spinner-container" *ngIf="isLoading">
    <p-progressSpinner></p-progressSpinner>
</div>
<div *transloco="let t">
    <section *ngIf="!isLoading" class="max-w-4xl p-6 sm:my-4 mx-auto bg-white sm:rounded-2xl sm:border sm:border-gray-300">
        <div class="grid grid-cols-3">
            <a href="/zeitbuchung" class="row-start-2 md:row-start-1 w-fit px-3 transition-colors duration-300 flex items-center justify-center py-1 rounded-md">
                <i class="pi pi-angle-left"></i><span> {{t('back_button')}}</span>
            </a>
            <a href="" class="row-start-2 md:row-start-2 w-fit px-3 transition-colors duration-300 flex items-center justify-center py-1 rounded-md">
             <i class="pi pi-angle-left"></i><span> {{t('dashboard')}} </span>
            </a>
            <h2 class="text-lg text-center content-center font-semibold text-gray-700">
                {{t('timebooking')}}
            </h2>
            <div class="text-right">
                <i class="pi pi-stopwatch" style="font-size: 2rem"></i>
            </div>
        </div>
        <form [formGroup]="formZeitbuchung" *ngIf="!isLoading">
            <div class="grid grid-cols-1 gap-2 mt-4 sm:grid-cols-2">
                <div class="mb-3"><app-form-date-picker formControlName="datum" inputId="datum"
                        labelName="{{t('date')}}{{t('required')}}"></app-form-date-picker></div>
                <div class="flex w-full gap-3 mb-3">
                    <app-form-input inputType="time" labelName="{{t('beginning')}}{{t('required')}}" formControlName="startzeit"
                        inputId="startzeit" />
                    <app-form-input inputType="time" labelName="{{t('ending')}}{{t('required')}}" formControlName="endzeit" inputId="endzeit" />
                    <app-form-input inputType="number" [step]="0.25" [min]="0" [max]="8" labelName="{{t('break')}}" inputId="pause"
                        formControlName="pause" inputId="pause" />
                </div>
                <div class="flex gap-3 justify-center mb-3">
                    <label for="homeOffice">{{t('home-office')}}</label>
                    <p-inputSwitch inputId="homeOffice" formControlName="homeOffice" />
                </div>
    
                @if(formZeitbuchung.controls.homeOffice.value === false){
                    <app-form-input inputType="text" labelName="{{t('worklocation')}}*" formControlName="arbeitsort" inputId="arbeitsort" />
                }
                
                <div class="flex justify-center mb-8 gap-2">
                    <button
                        class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none focus:bg-gray-600 disabled:bg-gray-200"
                        [disabled]="(formZeitbuchung.invalid || formZeitbuchung.pristine)" type="submit"
                        (click)="onSubmit()">
                        {{t('save')}}
                    </button>
                    <button
                        class="px-8 py-2.5 leading-5 text-primary transition-colors duration-300 transform border border-gray-200 rounded-md hover:bg-secondaryhover active:bg-primary active:transition-none"
                        (click)="resetForm()">
                        {{t('restore')}}
                    </button>
                </div>
            </div>
    
            @if (formZeitbuchung.controls.startzeit.errors && formZeitbuchung.dirty) {
            <p class="text-danger mt-3">
                {{ formZeitbuchung.controls.startzeit.errors['message'] }}
            </p>
            <p class="text-danger mt-3">
    
            </p>
            } @else if (formZeitbuchung.controls.endzeit.errors && formZeitbuchung.dirty) {
            <p class="text-danger mt-3">
                {{ formZeitbuchung.controls.endzeit.errors['message'] }}
            </p>
            }
    
            @if (formZeitbuchung.invalid && formZeitbuchung.dirty) {
            <p class="text-danger mt-3">
                {{t('fillout_msg')}}{{t('required')}}
            </p>
            }
        </form>
    
        <div class="mt-3" *ngIf="(!isLoading && _isEdit)">
            <h3 class="text-left content-center text-lg text-gray-700">
                {{t('activities')}} ({{ gesamtStundenSignal() }}h/{{ startEndePauseSignal() }}h)
            </h3>
            <div class="h-2 w-full bg-gray-300 mb-4 rounded-full"></div>
            <form [formGroup]="formTaetigkeit">
                <div class="grid grid-cols-1 gap-2 mt-4 sm:grid-cols-2">
                    <div class="flex w-full gap-3 mb-3">
                        <app-form-dropdown class="min-w-48" labelName="{{t('cost_centre')}}{{t('required')}}" inputId="kostentraeger"
                            inputType="dropdown" formControlName="kostentraeger" [optionList]="kostentraegerAuswahlSignal()"
                            optionLabel="name" optionValue="id" [filter]="true"></app-form-dropdown>
                        <app-form-input class="min-w-16" inputType="number" [step]="0.25" [min]="0" [max]="8"
                            labelName="{{t('hours')}}" inputId="stunden" formControlName="stunden"
                            inputId="stunden"></app-form-input>
                    </div>
                    <div class="flex w-full gap-3 mb-3">
                        <app-form-input class="w-full" labelName="{{t('summary')}}{{t('required')}}" inputId="zusammenfassung"
                            inputType="text" formControlName="zusammenfassung"></app-form-input>
                        <button
                            class="mt-9 h-8 w-8 text-white transition-colors duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none focus:bg-gray-600 disabled:bg-gray-200"
                            [disabled]="formTaetigkeit.invalid" type="submit" (click)="onSubmitTaetigkeit()">+</button>
                    </div>
                </div>
                <div class="flex w-full mb-3">
                    <app-form-input class="w-full" labelName="{{t('note')}}{{t('required')}}" inputId="notiz" inputType="textarea"
                        formControlName="notiz"></app-form-input>
                </div>
    
            </form>
            <div class="">
                <p-table [scrollable]="true" scrollHeight="300px" #smallTable2 styleClass="p-datatable-sm"
                    [value]="taetigkeitenSignal()">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{t('cost_centre')}}</th>
                            <th>{{t('hours')}}</th>
                            <th>{{t('summary')}}</th>
                            <th>{{t('note')}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-taetigkeit>
                        <tr>
                            <td>
                                {{ taetigkeit.kostentraegerName }}
                            </td>
                            <td>
                                {{ convertToDecimal(taetigkeit.stunden) }}
                            </td>
                            <td>
                                {{ taetigkeit.zusammenfassung }}
                            </td>
                            <td class="max-w-12">
                                <span class="line-clamp-1">{{ taetigkeit.notiz}}</span>
                            </td>
                            <td>
                                <div class="text-center">
                                    <button type="button" (click)="deleteTaetigkeit(taetigkeit.id)"
                                        class="text-red-500 hover:bg-red-100 rounded-xl p-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="size-6">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="99" style="text-align: center">
                                {{t('no-entries')}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </section>
</div>