export function roundDateToNearestQuarterHour(date: Date): Date {
  const time = date.getTime();
  const oneMinute = 60 * 1000;
  const oneQuarterHour = 15 * oneMinute;
  const roundedDate = new Date(Math.round(time / oneQuarterHour) * oneQuarterHour);
  return roundedDate;
}

export function roundToQuarter(num: number): number {
  return Math.round(num * 4) / 4;
}