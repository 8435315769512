<div *transloco="let t">
    <h3 class="text-xl sm:grid sm:place-items-center sm:text-3xl sm:py-3">{{t('current')}} {{t('timebookings')}}</h3>
    <div class="flex justify-center">
        <div class="sm:grid sm:place-items-center sm:mx-10 sm:mt-4">
            <table class="sm:grid sm:place-items-center sm:table-auto sm:w-full sm:h-full sm:border-collapse">
                <thead class="border-b-2 border-gray-500">
                <tr>
                    <th class="px-1 py-0.5 sm:px-4 sm:py-2">{{t('beginning')}}</th>
                    <th class="px-1 py-0.5 sm:px-4 sm:py-2">{{t('ending')}}</th>
                    <th class="px-1 py-0.5 sm:px-4 sm:py-2">{{t('date')}}</th>
                </tr>
                </thead>
                <tbody>
                    @for(booking of _timebookings; track booking.id){
                        <tr>
                            <td class="px-1 py-0.5 sm:px-4 sm:py-2">{{ booking.startzeit }}</td>
                            <td class="px-0 py-0.5 sm:px-4 sm:py-2 sm:border-r-2 sm:border-gray-500">{{ booking.endzeit }}</td>
                            <td class="px-1 py-0.5 sm:px-4 sm:py-2"><a href="/zeitbuchung/detail/'+{{booking.id}}">{{ booking.datum }}</a></td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>