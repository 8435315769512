<div [ngClass]="noMargin() ? '' : 'mb-3'">
  @switch (inputType()) { @case ('text') {
  <div>
    <label [for]="inputId()">{{ labelName() }}</label>
    <input [ngClass]="
        control().invalid && control().touched
          ? 'border-danger'
          : ' border-gray-400'
      "
      class="block h-11 w-full px-4 py-2 mt-2 text-gray-700 hover:border-black bg-white border rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
      type="text" [id]="inputId()" [value]="value()" (input)="inputOnChange($event)" [readonly]="readonly()"
      [disabled]="disabled()" />
  </div>
  } @case ('price') {
  <div>
    <label [for]="inputId()">{{ labelName() }}</label>
    <input [ngClass]="
        control().invalid && control().touched
          ? 'border-danger'
          : ' border-gray-400'
      "
      class="block h-11 w-full px-4 py-2 mt-2 text-gray-700 hover:border-black bg-white border border-black rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
      type="number" step="0.01" [id]="inputId()" [value]="value()" (input)="inputOnChange($event)"
      [readonly]="readonly()" [disabled]="disabled()" />
  </div>
  } @case ('password') {
  <div>
    <label [for]="inputId()">{{ labelName() }}</label>
    <input [ngClass]="
        control().invalid && control().touched
          ? 'border-danger'
          : ' border-gray-400'
      "
      class="block h-11 w-full px-4 py-2 mt-2 text-gray-700 hover:border-black bg-white border border-black rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
      type="password" step="0.01" [id]="inputId()" [value]="value()" (input)="inputOnChange($event)"
      [readonly]="readonly()" [disabled]="disabled()" />
  </div>
  } @case('number') {
  <div>
    <label [for]="inputId()">{{ labelName() }}</label>
    <input [ngClass]="
        control().invalid && control().touched
          ? 'border-danger'
          : ' border-gray-400'
      "
      class="block h-11 w-full px-4 py-2 mt-2 text-gray-700 hover:border-black bg-white border border-black rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
      type="number" [max]="max()" [min]="min()" step="0.01" [id]="inputId()" [value]="value()"
      (input)="inputOnChange($event)" [step]="step()" [readonly]="readonly()" [disabled]="disabled()" />
  </div>
  } @case ('search') {
  <div>
    <i class="pi pi-search text-black absolute translate-x-3 translate-y-3"></i>
    <input aria-label="search" [ngClass]="
        control().invalid && control().touched
          ? 'border-danger'
          : ' border-gray-400'
      "
      class="block pl-10 h-11 w-full px-4 py-2 text-gray-700 hover:border-black bg-white border rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
      type="text" [id]="inputId()" [value]="value()" (input)="inputOnChange($event)" [readonly]="readonly()"
      [disabled]="disabled()" />
  </div>
  } @case ('textarea') {
  <div>
    <label [for]="inputId()">{{ labelName() }}</label>
    <textarea [ngClass]="
        control().invalid && control().touched
          ? 'border-danger'
          : ' border-gray-400'
      "
      class="block h-32 w-full px-4 py-2 mt-2 text-gray-700 hover:border-black bg-white border rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
      [id]="inputId()" [value]="value()" (input)="inputOnChange($event)" [readonly]="readonly()"
      [disabled]="disabled()"></textarea>
  </div>
  } @case ('time') {
  <div>
    <label [for]="inputId()">{{ labelName() }}</label>
    <input [ngClass]="control().invalid && control().touched ? 'border-danger' : ' border-gray-400'"
      class="block h-11 w-full px-4 py-2 mt-2 text-gray-700 hover:border-black bg-white border rounded-md focus:border-primary focus:outline-none disabled:text-gray-500 disabled:border-gray-100"
      type="text" [id]="inputId()" [value]="value()" (input)="inputOnChange($event)" [readonly]="readonly()"
      [disabled]="disabled()" />
    @if (!isValidTime()) {
    <div *transloco="let t" class="text-red-500 text-sm mt-2">
      {{ t('filloutnumber') }}
    </div>
    }
  </div>
  }}
</div>