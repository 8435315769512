import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { mitarbeiterExportFilteredMitarbeiter } from 'src/app/global/apis/backend/fn/mitarbeiter/mitarbeiter-export-filtered-mitarbeiter';
import { mitarbeiterExportMitarbeiter } from 'src/app/global/apis/backend/fn/mitarbeiter/mitarbeiter-export-mitarbeiter';
import { mitarbeiterFilterMitarbeiter } from 'src/app/global/apis/backend/fn/mitarbeiter/mitarbeiter-filter-mitarbeiter';
import { MitarbeiterService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
  selector: 'app-mitarbeiter',
  templateUrl: './mitarbeiter.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'personalnummer',
        'vorname',
        'nachname',
        'email',
        'telefon',
        'unternehmen',
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: mitarbeiterFilterMitarbeiter,
        exportAll: mitarbeiterExportFilteredMitarbeiter,
        exportSelected: mitarbeiterExportMitarbeiter,
        deactivateSelected: null,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
        LazyTableService<MitarbeiterService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent,
  ]
})
export class MitarbeiterComponent {
  diaRef!: DynamicDialogRef;
  public dataService: LazyTableService<MitarbeiterService> = inject(
        LazyTableService<MitarbeiterService>
  );

  public cols = [
    { field: 'personalnummer', header: 'employeenumber', type: 'text' },
    { field: 'vorname', header: 'firstname', type: 'text' },
    { field: 'nachname', header: 'surname', type: 'text' },
    { field: 'email', header: 'email', type: 'text' },
    { field: 'telefon', header: 'phone', type: 'text' },
    { field: 'unternehmen', header: 'company', type: 'text' },
  ];

  constructor() { }
}
