/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface AccountingUploadAccountingFile$Params {
  Date?: string;
  Company?: string;
  Purpose?: string;
  DocumentLibraryID?: string | null;
  UploadPath?: string | null;
      body?: {
'UploadedFile'?: Blob | null;
}
}

export function accountingUploadAccountingFile(http: HttpClient, rootUrl: string, params?: AccountingUploadAccountingFile$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, accountingUploadAccountingFile.PATH, 'post');
  if (params) {
    rb.query('Date', params.Date, {});
    rb.query('Company', params.Company, {});
    rb.query('Purpose', params.Purpose, {});
    rb.query('DocumentLibraryID', params.DocumentLibraryID, {});
    rb.query('UploadPath', params.UploadPath, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<string>;
    })
  );
}

accountingUploadAccountingFile.PATH = '/api/Accounting';
