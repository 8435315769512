import { Component, inject, OnInit } from '@angular/core';
import { ZeitbuchungService } from 'src/app/global/apis/backend/services';
import { FilterZeitbuchungCommand, ZeitbuchungFilter } from 'src/app/global/apis/backend/models';
import { Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  standalone: true,
  selector: 'app-current-time-bookings',
  imports: [TranslocoDirective],
  templateUrl: './current-time-bookings.component.html',
})
export class CurrentTimeBookingsComponent implements OnInit {
  constructor(private router: Router){}
  private readonly _timebookingService = inject(ZeitbuchungService);
  public _timebookings: ZeitbuchungFilter[] = [];

  ngOnInit(): void {
    this.loadTimebookings();
  }

  edit(t: ZeitbuchungFilter): void {
    this.router.navigate(['/zeitbuchung/detail'+'/'+t.id]);
  }

  loadTimebookings(): void {
    const params: FilterZeitbuchungCommand = {
      tableFilterPayload: {
        first: 0,
        rows: 3,
        sortField: 'datum',
        sortOrder: -1
      }
    };

    this._timebookingService.zeitbuchungFilterZeitbuchung({body: params })
      .subscribe((response) => {
        this._timebookings = response.data!;
      });
  }
}
