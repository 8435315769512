<p-table #dt [value]="$data()" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25, 50, 100]" [rowHover]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{currentPage} von {totalPages} Seiten mit {totalRecords} Einträgen"
  [totalRecords]="$totalRecords()" sortMode="single" selectionMode="multiple" [scrollable]="true"
  [resizableColumns]="false" [reorderableColumns]="true" columnResizeMode="none" [columns]="cols" [lazy]="true"
  (onLazyLoad)="loadDataLazy($event)" dataKey="id" [loading]="$loaded()" [selectionPageOnly]="true"
  [(selection)]="selectedData" (selectionChange)="onSelectionChange($event)" stateStorage="local"
  stateKey="{{ tableID }}" [filterDelay]="0" [globalFilterFields]="globalFilters">
  <ng-template pTemplate="caption">
    <div class="flex gap-2">
      <p-button pTooltip="Auswahl aufheben" tooltipPosition="bottom" icon="pi pi-minus-circle"
        (click)="clearSelection()" [disabled]="selectedData().length < 1" [hidden]="isSmallScreen"></p-button>
      <p-button pTooltip="Filter zurücksetzen" tooltipPosition="bottom" icon="pi pi-filter-slash" (click)="clear()"
        [disabled]="searchValue.length > 0" [hidden]="isSmallScreen"></p-button>
      <app-form-dropdown *transloco="let t" [optionList]="allCols" optionLabel="header" inputId="columnselection" labelName=""
        [(ngModel)]="cols" (onChange)="dt.saveState()" (onPanelHide)="dt.saveState()" [multipleSelection]="true"
        selectedItemsLabel="{{ t('columns_selected') }}"></app-form-dropdown>
      <app-form-input #search labelName="" inputType="search" [(ngModel)]="searchValue"
        (input)="dt.filterGlobal($any($event.target).value, 'contains')" inputId="search"></app-form-input>
      
        <p-button icon="pi pi-file-export" (click)="exportAll()" pTooltip="Export" tooltipPosition="bottom"
        [hidden]="isSmallScreen"></p-button>

        <div class="justify-center">
          @if(addButtonVisible){
            <p-button pTooltip="Neuen Eintrag anlegen" tooltipPosition="bottom" icon="pi pi-plus" (click)="add()"></p-button>
            }
            @if (isDeactivateAvailable()) {
            <p-button icon="pi pi-trash" pTooltip="Ausgewählte Einträge löschen" tooltipPosition="bottom"
              (click)="deactivateSelected()" [disabled]="selectedData().length < 1" [hidden]="isSmallScreen"></p-button>
            }
            @if (isArchiveAvailable()){
            <p-button icon="pi pi-server" (click)="archiveSelected()" pTooltip="Ausgewählte Einträge archivieren"
              tooltipPosition="bottom" [disabled]="selectedData().length < 1"></p-button>
            }
            <p-menu [hidden]="isSmallScreen" appendTo="body" #menu [model]="exportOptions()" [popup]="true" />
            <p-button [hidden]="isSmallScreen" icon="pi pi-file-export" (click)="menu.toggle($event)" pTooltip="Export"
              tooltipPosition="bottom"></p-button>
      </div>

    </div>
    <p-confirmDialog key="lazy-table-confirmation-dialog" header="Confirmation"
      icon="pi pi-exclamation-triangle"></p-confirmDialog>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="header-cell" style="width: 4rem" [hidden]="isSmallScreen">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      @for(col of columns; track $index){ @if(columns[$index].progressBar &&
      columns[$index + 1].progressBar ){
      <th class="header-cell" pResizableColumn [hidden]="isSmallScreen">
        {{ columns[$index].header + "/" + columns[$index + 1].header }}
      </th>
      } @else {@if(columns[$index].progressBar) {} @else {
      <th class="header-cell" pSortableColumn="{{ col.field }}" pReorderableColumn pResizableColumn [hidden]="isSmallScreen">
        {{ col.header | transloco }}
        @if(!col.selectionFilter && !col.noFilter){
        <p-columnFilter type="{{ col.type }}" field="{{ col.field }}" display="menu" [showOperator]="false"
          [maxFractionDigits]="2"></p-columnFilter>
        } @else { @if(col.selectionFilter){
        <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.type === 'enum' ? 'enumIn' : 'in'"
          [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false"
          [showClearButton]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect [showHeader]="false" optionValue="value" optionLabel="name"
              [ngModel]="getSelectionFilterValues(col.field)?.values" (onChange)="
                filter($event.value);
                updateSelectionFilterValues(col.field, $event.value)
              " [options]="col.selectionFilter" placeholder="Any">
              <ng-template let-option pTemplate="item">
                @if(col.tag){
                <p-tag ngClass="data-{{ option.name }}">{{
                  option.name
                  }}</p-tag>
                } @else {
                {{ option.name }}
                }
              </ng-template>
            </p-multiSelect>
            <div style="height: 10px"></div>
            <p-button [outlined]="true" (click)="filter(null); clearSelectionFilters(col.field)"
              label="Löschen"></p-button>
          </ng-template>
        </p-columnFilter>
        } }
        <p-sortIcon field="{{ col.field }}"></p-sortIcon>
      </th>
      }} }
      <th class="header-cell" style="min-width: 4rem" [hidden]="isSmallScreen">
        <p *transloco="let t">{{ t('actions') }}</p>
      </th>
      <th class="header-cell" style="min-width: 4rem" [hidden]="!isSmallScreen">
        <p>Details</p>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr (dblclick)="edit($event, rowData)" (click)="editSmall($event, rowData)" [pSelectableRow]="rowData">
      <td [hidden]="isSmallScreen">
        <p-tableCheckbox [value]="rowData" [disabled]="true"></p-tableCheckbox>
      </td>
      @for(col of columns; track $index){ @if(col.transform){ @if(col.link &&
      (rowData[col.field]) !== null){
      <td [hidden]="isSmallScreen">
        <a class="text-primary underline hover: text-primary" [routerLink]="col.link(rowData[col.field])">
          {{ col.transform(rowData[col.field]) }}</a>
      </td>
      } @else {
      <td [hidden]="isSmallScreen">
        @if(col.tag){
        <p-tag *ngIf="!col.progressBar && col.tag" ngClass="data-{{ col.transform(rowData[col.field]) }}">{{
          col.transform(rowData[col.field]) }}</p-tag>
        } @else { @switch(col.type){ @case('time'){
        {{ col.transform(rowData[col.field]) | date : "HH:mm" }}
        } @case('date'){
        {{ col.transform(rowData[col.field]) | date }}
        } @default { {{ col.transform(rowData[col.field]) }} } } }
      </td>
      } } @else { @if(columns[$index].progressBar && columns[$index +
      1].progressBar ){
      <td [hidden]="isSmallScreen">
        <div>
          <p-progressBar [value]="
              (
                (rowData[columns[$index].field] /
                  rowData[columns[$index + 1].field]) *
                100
              ).toFixed(0)
            " [style]="{ height: '6px' }"></p-progressBar>
          <span class="progress-bar-container">{{ rowData[columns[$index].field] }}/{{
            rowData[columns[$index + 1].field]
            }}</span>
        </div>
      </td>
      } @else { @if(columns[$index].progressBar){ }@else { @if(col.link &&
      (rowData[col.field]) !== null){
      <td [hidden]="isSmallScreen">
        <a [routerLink]="col.link(rowData[col.field])">
          {{ rowData[col.field] }}</a>
      </td>
      } @else {
      <td [hidden]="isSmallScreen">
        @if(col.tag){
        <p-tag *ngIf="!col.progressBar && col.tag" ngClass="data-{{ rowData[col.field] }}">{{ rowData[col.field]
          }}</p-tag>
        } @else { @switch(col.type){ @case('time'){
        {{ rowData[col.field] | date : "HH:mm" }}
        } @case('date'){
        {{ rowData[col.field] | date }}
        } @default { {{ rowData[col.field] }} } }}
      </td>
      } } } } }
      <td [hidden]="isSmallScreen">
        <p-button icon="pi pi-pencil" [text]="true" (click)="menuClick('Bearbeiten', rowData)" size="small"
          pTooltip="Bearbeiten" tooltipPosition="bottom"></p-button>
        @if(isDeactivateAvailable()){
        <p-button icon="pi pi-trash" [text]="true" (click)="menuClick('Löschen', rowData)" size="small"
          pTooltip="Löschen" tooltipPosition="bottom"></p-button>
        }
      </td>
      <td [hidden]="!isSmallScreen">
        <div class="card border rounded-lg shadow-lg">
          <div class="card-body p-2">
            <div class="p-0" *ngFor="let col of columns">
              <span class="font-bold">{{ col.header }}:</span> {{ rowData[col.field] }}
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7" *transloco="let t">{{ t('no-entries') }}</td>
    </tr>
  </ng-template>
</p-table>

<style scoped>
  .header-cell {
    white-space: normal; /* Allows line breaks and wrapping */
    word-wrap: break-word; /* Ensures long words break correctly */
  }
</style>