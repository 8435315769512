import { CommonModule } from '@angular/common';
import {
  Component,
  inject
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AccountingUploadAccountingFile$Params } from 'src/app/global/apis/backend/fn/accounting/accounting-upload-accounting-file';
import { AccountingService } from 'src/app/global/apis/backend/services';
import { MessageService } from 'primeng/api';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { FormDatePickerComponent } from 'src/app/shared/ui/form-date-picker/form-date-picker.component';
import { FormFileUploadComponent } from 'src/app/shared/ui/form-file-upload/form-file-upload.component';
import { FormInputComponent } from 'src/app/shared/ui/form-input/form-input.component';
import { toISODateString } from 'src/app/global/helper/isoDateFormater';

@Component({
  selector: 'app-accounting.detail',
  templateUrl: './accounting.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FloatLabelModule,
    InputTextModule,
    FormInputComponent,
    FormFileUploadComponent,
    FormDatePickerComponent,
    TableModule
  ],
})

export default class AccountingDefaultComponent{
  public invalidFileExtensionMessage: string | null = null;
  private readonly _fb = inject(FormBuilder);
  private readonly _messageService = inject(MessageService);
  private readonly _accountingService = inject(AccountingService);
  private _file: Blob | null = null;

  private readonly __default_company = 'ALPSCALE';
  private readonly __default_purpose = 'unknown';

  public formAccountingUpload = this._fb.group({
    date: [new Date, [Validators.required]],
    company: ['', [Validators.required]],
    purpose: ['', [Validators.required]],
    file: [null as Blob | null, [Validators.required]]
  })

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const cFile = input.files[0];
      const cAllowedFileExtensions = ['pdf', 'jpg', 'jpeg', 'png'];

      this.invalidFileExtensionMessage = null;

      if (!cAllowedFileExtensions.some(ext => cFile.name.endsWith(ext))) {
        this.invalidFileExtensionMessage = 'Invalid file extension. Allowed file extensions are: ' + cAllowedFileExtensions.join(', ');
        this.formAccountingUpload.controls['file'].setValue(null);
        return;
      }

      this.formAccountingUpload.controls['file'].setValue(cFile);
      }

    }
  

  onSubmit(): void {
    const newUploadAccountingFiles: AccountingUploadAccountingFile$Params = {
      Date: toISODateString(this.formAccountingUpload.get('date')?.value ?? new Date()),
      Company: this.formAccountingUpload.get('company')?.value ?? this.__default_company,
      Purpose: this.formAccountingUpload.get('purpose')?.value ?? this.__default_purpose,
      body: {
        UploadedFile: this.formAccountingUpload.get('file')?.value as Blob
      } 
    };

    this._accountingService.accountingUploadAccountingFile$Response(newUploadAccountingFiles)
            .subscribe(() => {
                this._messageService.add({
                    severity: 'success',
                    summary: 'Upload successful',
                    detail:
                        'The file has been uploaded successfully!',
                });
            });
    
    this.resetForm();        
  }

  resetForm(): void {
    this.formAccountingUpload.setValue({
      date: new Date,
      company: '',
      purpose: '',
      file: null
    })
  }
}
