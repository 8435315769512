<section *transloco="let t" class="grid grid-cols-1 gap-8 px-6 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 mt-2">
    <div class="bg-black rounded-lg shadow-md shadow-gray-200 ">
        <app-quick-actions></app-quick-actions>
    </div>
    
    <div class="bg-white rounded-lg shadow-md shadow-gray-200 ">
        <app-current-time-bookings></app-current-time-bookings>
    </div>

    <div class="bg-white rounded-lg shadow-md shadow-gray-200 ">
        <app-bookings-warning></app-bookings-warning>
    </div>

    <!--
    <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200 ">
        <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#FF4C61" fill-opacity="0.05" />
                <path d="M26 32L32 38L36 34L43.405 41.405" stroke="#FF4C61" stroke-width="2" stroke-linecap="square" />
                <path d="M43.405 41.405L44 42" stroke="#FF4C61" stroke-width="2" stroke-linecap="round" />
                <path d="M44 39V42H41" stroke="#FF4C61" stroke-width="2" stroke-linecap="square" />
            </svg>

            <div class="mx-2">
                <h3 class="text-2xl font-medium text-gray-800">9 <span class="text-xl text-gray-600">(14%)</span></h3>
                <p class="mt-1 text-sm text-gray-500">Verrechenbarkeit</p>
            </div>
        </div>
    </div>

    
    <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200 ">
        <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#4CB8FF" fill-opacity="0.07" />
                <path
                    d="M42 26V44H31C30.2044 44 29.4413 43.6839 28.8787 43.1213C28.3161 42.5587 28 41.7956 28 41V29C28 28.2044 28.3161 27.4413 28.8787 26.8787C29.4413 26.3161 30.2044 26 31 26H42Z"
                    stroke="#4CB8FF" stroke-width="2" stroke-linecap="square" />
                <path d="M28 41C28 40.2044 28.3161 39.4413 28.8787 38.8787C29.4413 38.3161 30.2044 38 31 38H42"
                    stroke="#4CB8FF" stroke-width="2" stroke-linecap="square" />
            </svg>

            <div class="mx-2">
                <h3 class="text-2xl font-medium text-gray-800">92%</h3>
                <p class="mt-1 text-sm text-gray-500">Getätigte Zeitbuchungen</p>
            </div>
        </div>
    </div>
    -->
</section>