import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { taetigkeitDeleteTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-delete-taetigkeit';
import { taetigkeitExportFilteredTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-export-filtered-taetigkeit';
import { taetigkeitExportTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-export-taetigkeit';
import { taetigkeitFilterTaetigkeit } from 'src/app/global/apis/backend/fn/taetigkeit/taetigkeit-filter-taetigkeit';
import { TaetigkeitService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
  selector: 'app-taetigkeit',
  templateUrl: './taetigkeit.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'mitarbeiter',
        'kostentraeger',
        'zusammenfassung'
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: taetigkeitFilterTaetigkeit,
        exportAll: taetigkeitExportFilteredTaetigkeit,
        exportSelected: taetigkeitExportTaetigkeit,
        deactivateSelected: taetigkeitDeleteTaetigkeit,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
        LazyTableService<TaetigkeitService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent,
  ]
})
export class TaetigkeitComponent {
  diaRef!: DynamicDialogRef;
  public dataService: LazyTableService<TaetigkeitService> = inject(
        LazyTableService<TaetigkeitService>
  );

  public cols = [
    { field: 'mitarbeiter', header: 'employee', type: 'text' },
    { field: 'kostentraeger', header: 'cost_centre', type: 'text' },
    { field: 'datum', header: 'date', type: 'date' },
    { field: 'zusammenfassung', header: 'summary', type: 'text' },
    { field: 'stunden', header: 'hours', type: 'text' },
  ];

  constructor() { }
}
