import { CommonModule } from '@angular/common';
import { Component, inject} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { kostentraegerDeActivateKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-de-activate-kostentraeger';
import { kostentraegerExportFilteredKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-export-filtered-kostentraeger';
import { kostentraegerExportKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-export-kostentraeger';
import { kostentraegerFilterKostentraeger } from 'src/app/global/apis/backend/fn/kostentraeger/kostentraeger-filter-kostentraeger';
import { KostentraegerService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
  selector: 'app-kostentraeger',
  templateUrl: './kostentraeger.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'name',
        'beschreibung'
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: kostentraegerFilterKostentraeger,
        exportAll: kostentraegerExportFilteredKostentraeger,
        exportSelected: kostentraegerExportKostentraeger,
        deactivateSelected: kostentraegerDeActivateKostentraeger,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
        LazyTableService<KostentraegerService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent
  ],
})
export class KostentraegerComponent {

  constructor() {}

  private _status = '';

  diaRef!: DynamicDialogRef;
  public dataService: LazyTableService<KostentraegerService> = inject(
        LazyTableService<KostentraegerService>,
  );

  public cols = [
    {
      field: 'aktiv',
      header: 'status',
      type: 'text',
      tag: true,
      transform: (value: any) => (value ? 'activ' : 'inactiv'),
      filter: [{ action: 'equals', value: 'activ' }],
      selectionFilter: [
        { value: true, name: 'activ' },
        { value: false, name: 'inactiv' },
      ],
    },
    { field: 'name', header: 'name', type: 'text' },
    { field: 'beschreibung', header: 'description', type: 'text' },
    { field: 'stundensatz', header: 'pph', type: 'numeric' },
    {
      field: 'intern',
      header: 'type',
      type: 'text',
      tag: true,
      transform: (value: any) => (value ? 'internal' : 'external'),
      filter: [{ action: 'equals', value: 'internal' }],
      selectionFilter: [
        { value: true, name: 'internal' },
        { value: false, name: 'external' },
      ],
    },

  ];
    
}
