<section class="max-w-4xl p-6 sm:my-4 mx-auto bg-white sm:rounded-2xl sm:border sm:border-gray-300">
  <div class="grid grid-cols-3">
      <div></div>
      <h2 class="text-lg text-center content-center font-semibold text-gray-700">
          Accounting
      </h2>
      <div class="text-right">
          <i class="pi pi-file-import" style="font-size: 2rem"></i>
      </div>
  </div>
  <form [formGroup]="formAccountingUpload">
      <div class="grid grid-cols-1 gap-2 mt-4 sm:grid-cols-2">
        <div>
          <div class="mb-3">
            <app-form-date-picker formControlName="date" inputId="date" labelName="Date*" />
          </div>  
          <div class="mb-3">  
            <app-form-input inputType="text" labelName="Company*" formControlName="company" inputId="company" />
          </div>  
          <div class="mb-3">  
            <app-form-input inputType="text" labelName="Purpose*" formControlName="purpose" inputId="purpose" />
          </div>
        </div>
          <div>
            <app-form-file-upload inputType="file" labelName="File*" formControlName="file" inputId="file" (change)="onFileSelected($event)"/>
            <!---->
          </div>        
          <div class="flex justify-center mb-8 gap-2">
              <button
                  class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none focus:bg-gray-600 disabled:bg-gray-200"
                  [disabled]="(formAccountingUpload.invalid || formAccountingUpload.pristine)" type="submit"
                  (click)="onSubmit()">
                  Upload
              </button>
              <button
                  class="px-8 py-2.5 leading-5 text-primary transition-colors duration-300 transform border border-gray-200 rounded-md hover:bg-secondaryhover active:bg-primary active:transition-none"
                  (click)="resetForm()">
                  Clear
              </button>
          </div>
      </div>


      @if (invalidFileExtensionMessage) {
      <p class="text-danger mt-3">
          {{ invalidFileExtensionMessage }}
      </p>
      }
  </form>
</section>