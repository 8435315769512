import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-login-failed',
  template: '<p *transloco="let t">{{t(\'login_failed\')}}</p>',
  styleUrls: [],
  standalone: true,
  imports: [TranslocoDirective]
})
export class FailedComponent {}
