<div class="grid-cols-1 justify-center items-center h-32 flex space-x-6 sm:grid-cols-3 sm:px-8 sm:py-2 sm:justify-center sm:h-64 xl:py-4 xl:px-6" *transloco="let t">
        <button [attr.aria-label]="('timebooking' | transloco) + '' + ('add' | transloco)" class="p-button sm:flex-1 sm:w-45 sm:h-45" (click)="shortcutNewTimeBooking()">
            <div class="sm:text-2xl">
                +
            </div>
            <svg class="stroke-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5">
                <path routerLinkActive="stroke-primary"
                    stroke-linecap="round" stroke-linejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </button>
        <button [attr.aria-label]="('cost_centre' | transloco) + ' ' + ('add' | transloco)" class="p-button sm:flex-1 sm:w-45 sm:h-45" (click)="shortcutNewCostCentre()">
            <div class="sm:text-2xl">
                +
            </div>
            <svg class="stroke-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5">
                <path routerLinkActive="stroke-primary"
                    stroke-linecap="round" stroke-linejoin="round"
                    d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </button>
        <button [attr.aria-label]="('employee' | transloco) + ' ' + ('add' | transloco)" class="p-button sm:flex-1 sm:w-45 sm:h-45" (click)="shortcutNewEmployee()">
            <div class="sm:text-2xl">
                +
            </div>
            <svg class="stroke-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5">
                <path routerLinkActive="stroke-primary"
                    stroke-linecap="round" stroke-linejoin="round"
                    d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
            </svg>
        </button>
</div>