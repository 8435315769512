/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accountingUploadAccountingFile } from '../fn/accounting/accounting-upload-accounting-file';
import { AccountingUploadAccountingFile$Params } from '../fn/accounting/accounting-upload-accounting-file';

@Injectable({ providedIn: 'root' })
export class AccountingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountingUploadAccountingFile()` */
  static readonly AccountingUploadAccountingFilePath = '/api/Accounting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountingUploadAccountingFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountingUploadAccountingFile$Response(params?: AccountingUploadAccountingFile$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return accountingUploadAccountingFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountingUploadAccountingFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountingUploadAccountingFile(params?: AccountingUploadAccountingFile$Params, context?: HttpContext): Observable<string> {
    return this.accountingUploadAccountingFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
