import { CommonModule, registerLocaleData } from '@angular/common';
import { Component, inject, LOCALE_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { MainlayoutComponent } from './shared/layout/mainlayout/mainlayout.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  imports: [
    RouterOutlet,
    MainlayoutComponent,
    CommonModule,
    ToastModule,
    TranslocoModule
  ]
})

export class AppComponent {
  constructor(
  ) {
    registerLocaleData(this.localeDe);
  }
  private localeDe = inject(LOCALE_ID);
  title = 'copacking-frontend';
  isIframe = false;
}
