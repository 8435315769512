import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true, 
  selector: 'app-quick-actions',
  imports: [TranslocoModule],
  templateUrl: './quick-actions.component.html'
})
export class QuickActionsComponent {
  constructor(private router: Router){}

  shortcutNewTimeBooking(){
    this.router.navigate(['/zeitbuchung/detail']);
  }

  shortcutNewCostCentre(){
    this.router.navigate(['/kostentraeger/detail']);
  }

  shortcutNewEmployee(){
    this.router.navigate(['/mitarbeiter/detail']);
  }
}
