import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { zeitbuchungDeActivate } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-de-activate';
import { zeitbuchungExportFilteredZeitbuchung } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-export-filtered-zeitbuchung';
import { zeitbuchungExportZeitbuchung } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-export-zeitbuchung';
import { zeitbuchungFilterZeitbuchung } from 'src/app/global/apis/backend/fn/zeitbuchung/zeitbuchung-filter-zeitbuchung';
import { ZeitbuchungService } from 'src/app/global/apis/backend/services';
import { LazyTableComponent } from 'src/app/lazy-table/lazy-table.component';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  LazyTableService,
  SERVICE_FUNCTIONS,
} from 'src/app/lazy-table/service/lazy-table.service';

@Component({
  selector: 'app-zeitbuchung',
  templateUrl: './zeitbuchung.component.html',
  styleUrls: [],
  providers: [
    DialogService,
    {
      provide: GLOBAL_SEARCH_COLLUMS,
      useValue: [
        'mitarbeiter'
      ],
    },
    {
      provide: SERVICE_FUNCTIONS,
      useValue: {
        getLazyData: zeitbuchungFilterZeitbuchung,
        exportAll: zeitbuchungExportFilteredZeitbuchung,
        exportSelected: zeitbuchungExportZeitbuchung,
        deactivateSelected: zeitbuchungDeActivate,
        archiveSelected: null,
      } as FunctionInjectionMap,
    },
        LazyTableService<ZeitbuchungService>,
  ],
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    LazyTableComponent,
  ]
})
export class ZeitbuchungComponent {
  diaRef!: DynamicDialogRef;
  // Private readonly destroy$ = new Subject();
  public dataService: LazyTableService<ZeitbuchungService> = inject(
        LazyTableService<ZeitbuchungService>
  );

  public cols = [
    { field: 'mitarbeiter', header: 'employee', type: 'text' },
    { field: 'datum', header: 'date', type: 'date' },
    { field: 'startzeit', header: 'beginning', type: 'time', noFilter: true, transform: (value: string) => new Date(`1970-01-01T${value}`) },
    { field: 'endzeit', header: 'ending', type: 'time', noFilter: true, transform: (value: string) => new Date(`1970-01-01T${value}`) },
    { field: 'pause', header: 'break', type: 'numeric' },
    { field: 'stundenExklPause', header: 'hwb', type: 'numeric' },
    { field: 'stundenInklPause', header: 'hib', type: 'numeric' },
    { field: 'stundenOffen', header: 'openhours', type: 'numeric' },
    { field: 'stundenVerrechenbar', header: 'bh', type: 'numeric' },
    { field: 'verrechenbarkeit', header: 'billability', type: 'numeric' },
    { field: 'arbeitsort', header: 'worklocation', type: 'string' },
  ];

  constructor() { }
}
