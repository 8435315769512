import {
  BreakpointObserver,
  Breakpoints
} from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  model,
  ModelSignal,
  Output,
  Signal,
  signal,
  ViewChild, OnInit
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  ConfirmationService,
  TableState
} from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { catchError, of, switchMap } from 'rxjs';
import { TableFilterModel } from '../global/apis/backend/models';
import { FormDropdownComponent } from '../shared/ui/form-dropdown/form-dropdown.component';
import { FormInputComponent } from '../shared/ui/form-input/form-input.component';
import { hasId } from './guards/type.guard';
import { AlpTableHeader, LazyDataService } from './models/alptable.models';
import {
  FunctionInjectionMap,
  GLOBAL_SEARCH_COLLUMS,
  SERVICE_FUNCTIONS,
} from './service/lazy-table.service';
import { TranslocoDirective, TranslocoModule, TranslocoService } from '@jsverse/transloco';

interface lazySelectionFilter {
  field: string;
  values: { value: any; matchMode: string; operator: string }[];
}

@Component({
  selector: 'app-lazy-table',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
    MenuModule,
    ReactiveFormsModule,
    CommonModule,
    ConfirmDialogModule,
    TableModule,
    CardModule,
    InputTextModule,
    ButtonModule,
    MultiSelectModule,
    IconFieldModule,
    InputIconModule,
    ProgressBarModule,
    TagModule,
    FormDropdownComponent,
    FormInputComponent,
    DropdownModule,
    TranslocoDirective,
    TranslocoModule
  ],
  templateUrl: './lazy-table.component.html',
  styleUrl: './lazy-table.component.scss',
})
export class LazyTableComponent implements OnInit {
  constructor(public breakpointObserver: BreakpointObserver, private translocoService: TranslocoService) { }
  @Input({ required: true }) allCols!: AlpTableHeader[];
  @Input({ required: true }) dataService!: LazyDataService<any>;
  @Input({ required: true }) tableID!: string;
  @Input() addButtonVisible: boolean = true;
  @Input() targetRoute: string | null = null;
  @Input() targetRouteId: string = 'id';
  @Output() lazyLoadEvent = new EventEmitter<TableFilterModel>();

  @ViewChild('dt', { static: true }) table!: Table;
  @ViewChild('search', { static: true }) searchInput!: HTMLInputElement;

  private destroyRef = inject(DestroyRef);

  public selectionFilters = model<lazySelectionFilter[]>([]);
  public selectedData: ModelSignal<any[]> = model([] as any[]);
  public cols: AlpTableHeader[] = [];
  public selectAll: boolean = false;
  public $loaded!: Signal<boolean>;
  public $data!: Signal<any>;
  public $totalRecords!: Signal<number>;
  public exportOptions = signal([
    {
      label: 'Export Optionen',
      items: [
        {
          label: 'Alles exportieren',
          icon: 'pi pi-file',
          command: () => this.exportAll(),
        },
        {
          label: 'Ausgewählte exportieren',
          icon: 'pi pi-list',
          command: () => this.exportSelected(),
        },
      ],
    },
  ]);

  public searchValue: string = '';

  public isSmallScreen: boolean = false;

  private router: Router = inject(Router);
  private confirmationService = inject(ConfirmationService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private _functions: FunctionInjectionMap = inject(SERVICE_FUNCTIONS);
  public globalFilters = inject(GLOBAL_SEARCH_COLLUMS);
  //private confirmationService = inject(); // Copacking Specific, but would be smart to include in table

  ngOnInit() {
    const localTable = localStorage.getItem(this.tableID);
    if (localTable) {
      //reload cols from Localstorage for the drop down
      const parsedLocalTable = JSON.parse(localTable) as TableState;

      const storedCols = parsedLocalTable.columnOrder;
      this.cols =
        storedCols && storedCols?.length > 0
          ? this.allCols.filter((x) => storedCols?.includes(x.field))
          : this.allCols;

      this.allCols.forEach((col) => {
        if (parsedLocalTable.filters) {
          const colFilters = parsedLocalTable.filters[col.field];
          if (col.selectionFilter && Array.isArray(colFilters)) {
            const value = colFilters[0].value;
            this.selectionFilters.update((filters) => {
              return [...filters, { field: col.field, values: value }];
            });
          }
        }
      });

      //set the Input field to the stored search
      if (parsedLocalTable.filters?.['global']) {
        this.searchValue =
          '' + (parsedLocalTable.filters?.['global'] as any).value;
      }
    } else {
      this.cols = this.allCols;
    }
    this.$data = this.dataService.getDataSignal();
    this.$totalRecords = this.dataService.getEntriesSignal();
    this.$loaded = this.dataService.getIsLoadedSignal();

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmallScreen = result.matches;
      });
  }

  public loadDataLazy(event: any) {
    const x = event as TableFilterModel;
    this.dataService
      .getLazyData(x)
      .pipe(
        catchError(() => {
          this.table.clearState();
          this.table.reset();
          return [];
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public onSelectionChange(value = []) {
    if (this.isSmallScreen) {
      this.selectedData.set([]);
    } else {
      this.selectAll = value.length === this.$totalRecords();
      this.selectedData.set(value);
    }
  }

  public exportSelected() {
    this.dataService.exportSelectedEntries(this.selectedData());
  }

  public exportAll() {
    this.dataService.exportAllEntries(this.getCurrentFilterModel());
  }

  menuClick(label: string, entry: any) {
    switch (label) {
    case 'Bearbeiten':
      this.edit(new MouseEvent(''), entry);
      break;
    default:
      this.deactivateOne(entry.id);
    }
  }

  public deactivateSelected() {
    if (this.selectedData().length < 1) {
      return;
    }
    const selectedIDs = this.selectedData().reduce((ids, item) => {
      if (hasId(item)) {
        ids.push(item.id as string);
      }
      return ids;
    }, [] as string[]);

    const currentFilter = this.getCurrentFilterModel();

    this.confirmationService.confirm({
      header: this.translocoService.translate('confirm-header'),
      message: this.translocoService.translate('confirm-msg'),
      acceptLabel: this.translocoService.translate('yes'),
      rejectLabel: this.translocoService.translate('no'),
      key: 'lazy-table-confirmation-dialog',
      accept: () => {
        this.dataService
          .deactivateSelectedEntries(selectedIDs, currentFilter)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap(() => {
              this.loadDataLazy(currentFilter);
              return of();
            })
          )
          .subscribe();
        this.selectedData.set([]);
      },
      reject: () => { },
    });
  }

  public archiveSelected() {
    if (this.selectedData().length < 1) {
      return;
    }
    const selectedIDs = this.selectedData().reduce((ids, item) => {
      if (hasId(item)) {
        ids.push(item.id as string);
      }
      return ids;
    }, [] as string[]);
    const currentFilter = this.getCurrentFilterModel();
    this.dataService
      .archiveSelectedEntries(selectedIDs, currentFilter)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          this.loadDataLazy(currentFilter);
          return of();
        })
      )
      .subscribe();
    this.selectedData.set([]);
  }

  public add() {
    // Go to Detail for this Table
    this.router.navigate(['detail'], { relativeTo: this.route });
  }

  // Entry will be ID - Copacking specific change
  public edit(e: MouseEvent, entry: any) {
    if (e) {e.stopPropagation();}
    this.navigateToDetail(entry);
  }

  public editSmall(e: MouseEvent, entry: any) {
    if (e) {e.stopPropagation();}
    if (this.isSmallScreen) {
      this.navigateToDetail(entry);
    }
  }

  public deactivateOne(id: string) {
    this.confirmationService.confirm({
      header: this.translocoService.translate('confirm-header'),
      message: this.translocoService.translate('confirm-msg'),
      acceptLabel: this.translocoService.translate('yes'),
      rejectLabel: this.translocoService.translate('no'),
      key: 'lazy-table-confirmation-dialog',
      accept: () => {
        const currentFilter = this.getCurrentFilterModel();
        this.dataService
          .deactivateSelectedEntries([id], currentFilter)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap(() => {
              this.loadDataLazy(currentFilter);
              return of();
            })
          )
          .subscribe();
        this.selectedData.set([]);
      },
      reject: () => { },
    });
  }

  public clear() {
    this.selectionFilters.set([]);
    this.table.clear();
    this.table.saveState();
  }

  public clearSelection() {
    this.selectedData.set([]);
    this.table.selection = [];
    this.table.saveState();
  }

  public getColFields(): string[] {
    return this.cols.map((x) => '' + x.field);
  }

  public getColHeaders(): string[] {
    return this.cols.map((x) => '' + x.header);
  }

  public isArchiveAvailable(): boolean {
    return this.dataService.isArchiveAvailable();
  }

  public isDeactivateAvailable(): boolean {
    return this.dataService.isDeactivateAvailable();
  }

  public clearSelectionFilters(field: string) {
    this.selectionFilters.update((filter) => {
      const filterToUpdate = filter.find((f) => f.field === field);
      if (filterToUpdate) {
        filterToUpdate.values = [];
      }
      return filter;
    });
  }

  public updateSelectionFilterValues(
    field: string,
    value: {
      value: any;
      matchMode: string;
      operator: string;
    }[]
  ) {
    this.selectionFilters.update((filters) => {
      const existingFilters = filters.find((filter) => filter.field === field);
      if (existingFilters) {
        existingFilters.values = [...value];
      } else {
        filters = [...filters, { field: field, values: value }];
      }
      return filters;
    });
  }

  public getSelectionFilterValues(field: string) {
    return this.selectionFilters().find((filter) => filter.field === field);
  }

  private getCurrentFilterModel(): TableFilterModel {
    return {
      filters: this.table.filters,
      first: 0,
      globalFilter: this.table.globalFilterFields,
      multiSortMeta: this.table.multiSortMeta,
      rows: this.$totalRecords(),
      sortField: this.table.sortField,
      sortOrder: this.table.sortOrder,
    } as TableFilterModel;
  }

  private navigateToDetail(entry: any) {
    if (this.targetRoute) {
      this.router.navigate([this.targetRoute, entry[this.targetRouteId]]);
    } else {
      this.router.navigate(['detail', entry[this.targetRouteId]], { relativeTo: this.route });
    }
  }
}