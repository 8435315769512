import { CommonModule, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { RoleConstants } from '../../constants/role.constant';
import { LogoComponent } from '../../svg/logo/logo.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  imports: [LogoComponent, NgClass, CommonModule, RouterLink, RouterLinkActive]
})
export class SidebarComponent {
  public isOpen = false;
  private userRoles: string[] = [];
  public RoleConstants = RoleConstants;

  constructor(private _msalService: MsalService) {
    let accountInfo = this._msalService.instance.getActiveAccount();

    if (
      !accountInfo &&
            this._msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this._msalService.instance.getAllAccounts();
      this._msalService.instance.setActiveAccount(accounts[0]);
      accountInfo = this._msalService.instance.getActiveAccount();
    }

    this.userRoles = accountInfo?.idTokenClaims?.roles ?? [];
  }

  public isInRole(roles: string[]): boolean {
    return this.userRoles.some(role => roles.includes(role));
  }
}