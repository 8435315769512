import { Component } from '@angular/core';
import { TranslocoService, TranslocoDirective } from '@jsverse/transloco';
import { NgFor } from '@angular/common';
@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [NgFor, TranslocoDirective],
  templateUrl: './language-switcher.component.html'
})
export class LanguageSwitcherComponent {
  currentLang: string;  // Stores the current language
  languages: string[];  // Stores available languages
  constructor(private translocoService: TranslocoService) {
    // Get the current language and available languages
    this.currentLang = this.translocoService.getDefaultLang();
    const availableLangs = this.translocoService.getAvailableLangs();
    // Check if languages are provided as strings or objects, and extract accordingly
    if (Array.isArray(availableLangs) && typeof availableLangs[0] === 'string') {
      this.languages = availableLangs as string[];
    } else {
      this.languages = (availableLangs as { id: string; label: string }[]).map(lang => lang.id);
    }
  }

  onChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const langCode = target.value;
    
    // Update the current language and set it as active in Transloco
    this.translocoService.setActiveLang(langCode);
    this.currentLang = langCode;
  }
}