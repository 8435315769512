<div>
    <label [for]="inputId()">{{ labelName() }}</label>
    <!-- File upload container -->
    <div class="relative items-center justify-center w-full mt-2">
        <label [for]="inputId()" class="flex flex-col items-center justify-center w-full h-56 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white hover:bg-gray-100 hover:border-solid ">
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                </svg>
                <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
            </div>
            <input [id]="inputId()" type="file" class="absolute h-full w-full inset-0 opacity-0" [id]="inputId()" (input)="inputOnChanged($event)" />
        </label>
    </div> 
    <!-- Uploaded file info container -->
    <div class="relative grid grid-cols-[10%_90%] gap-2 mt-3 ml-1 border border-gray-200 rounded-md border-solid" *ngIf="file !== null && file.name !== ''">
        <div class="relative inset-0 flex items-center justify-center">
            <i class="pi pi-file" style="font-size: 2.2rem; color: gray;"></i>  
        </div>
        <div class="file-info">
            <h4 class="file-info-name">
                {{ file.name }}
            </h4>
            <p class="file-size">
              {{ formatBytes(file.size) }}
            </p>
        </div>
    </div>
</div>